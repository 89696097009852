import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../loader';
import { Button, Card, CardActions, CardContent, Input, Typography } from '@material-ui/core';
//import MukhiyaModal from './mukhiyaModal'; // Import the MukhiyaModal component
import { toast } from 'react-toastify';
import { addMukhiyas, deleteMukhiya, getMukhiyas, updateMukhiya } from '../../actions/mukhiyasAction';
import { useLocation } from '@reach/router';
import SearchModal from '../search/searchModal';
import Modal from '../../Utilities/Modal';
import SearchForm from '../search/searchForm';

function MukhiyasList() {
  const [isLoading, setLoader] = useState(false);
  const [MukhiyaSearchModal, setMukhiyaSearchModal] = useState(false);
  const [openMukhiyaEditModal, setOpenMukhiyaEditModal] = useState(false);
  const [mukhiyas, setMukhiyas] = useState([]);
  const [selectedMukhiya, setSelectedMukhiya] = useState(null);
  const [addMukhiya, setAddMukhiya] = useState(false);
  const [deletingMukhiya, setDeletingMukhiya] = useState(false);
  const [updatingMukhiya, setUpdatingMukhiya] = useState(false);
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url); // Function to fetch Mukhiyas from API
  const samajMitraIdId = params.get('volunteerId');

  const fetchMukhiyas = async () => {
    // setLoader(true);
    try {
      const response = await getMukhiyas(samajMitraIdId);
      console.log('response from getMukhiyasAPI', response?.data);

      // Combine independent and linked Mukhiyas if both are arrays
      const independentMukhiyas = Array.isArray(response?.data?.independent_mukhiyas)
        ? response?.data?.independent_mukhiyas
        : [];
      const linkedMukhiyas = Array.isArray(response?.data?.linked_mukhiyas) ? response?.data?.linked_mukhiyas : [];
      const normalizedMukhiyas = [
        ...independentMukhiyas,
        ...linkedMukhiyas.map((mukhiya) => ({
          ...mukhiya,
          mukhiya_name: mukhiya.mukhiya_name || mukhiya.name || '', // Adjust to fit actual field names
          _id: mukhiya._id || mukhiya.id || '', // Adjust as per the unique identifier
        })),
      ];
      setMukhiyas(normalizedMukhiyas);
    } catch (error) {
      console.log('error', error);
      toast.error('Failed to fetch names. Please try again.');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchMukhiyas();
  }, [samajMitraIdId]);

  const handleAddMukhiya = async (searchParams) => {
    console.log('searchparams details', searchParams);
    try {
      const mukhiyaData = {
        mukhiya_name: searchParams?.name,
        mukhiya_father_name: searchParams?.father_name,
        gautra: searchParams?.gautra,
        village: searchParams?.village,
        mobile: searchParams?.mobile_no,
        subcaste: searchParams?.subcaste,
      };
      setAddMukhiya(true);
      const response = await addMukhiyas(samajMitraIdId, mukhiyaData);
      if (response) {
        toast.success('Mukhiya added successfully');
        fetchMukhiyas();
      }
    } catch (e) {
      toast.error('Failed to add mukhiya. Please try again.');
      console.log(e);
    } finally {
      setAddMukhiya(false);
    }
  };

  const handleDeleteMukhiya = async (mukhiya) => {
    try {
      setDeletingMukhiya(true);
      const mukhiyaId = mukhiya?._id;
      console.log('mukhiya id', mukhiyaId);
      const response = await deleteMukhiya(samajMitraIdId, mukhiyaId);
      if (response.success) {
        toast.success('Mukhiya deleted successfully');
        fetchMukhiyas();
      }
    } catch (error) {
      toast.error('Failed to delete mukhiya');
      console.log('error', error);
    } finally {
      setDeletingMukhiya(false);
    }
  };

  const handleUpdateMukhiya = async (searchParams) => {
    try {
      const updatedData = {
        mukhiya_name: searchParams?.name,
        mukhiya_father_name: searchParams?.father_name,
        gautra: searchParams?.gautra,
        village: searchParams?.village,
        mobile: searchParams?.mobile_no,
        subcaste: searchParams?.subcaste,
      };

      setUpdatingMukhiya(true);

      const mukhiyaId = selectedMukhiya?._id;
      if (!mukhiyaId) {
        throw new Error('Mukhiya ID is undefined.');
      }

      const response = await updateMukhiya(samajMitraIdId, mukhiyaId, updatedData);

      if (response.status) {
        toast.success('Mukhiya updated successfully');

        setOpenMukhiyaEditModal(false);
        setSelectedMukhiya(null);
        fetchMukhiyas();
      }
    } catch (error) {
      toast.error('Failed to update mukhiya. Please try again.');
      console.error('Update Error:', error);
    } finally {
      setUpdatingMukhiya(false);
    }
  };

  const onClose = () => {
    setSelectedMukhiya(null);
    setOpenMukhiyaEditModal(false);
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner message="Loading mukhiya names..." />
      ) : deletingMukhiya ? (
        <LoadingSpinner message="Deleting Mukhiya..." />
      ) : addMukhiya ? (
        <LoadingSpinner message="Adding Mukhiya..." />
      ) : updatingMukhiya ? (
        <LoadingSpinner message="Adding Mukhiya..." />
      ) : (
        <>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h2>Mukhiya Names</h2>
            <Button
              onClick={() => {
                setMukhiyaSearchModal(true);
              }}
              style={{ backgroundColor: '#4169E1', color: 'white', borderRadius: '10px' }}
            >
              Create Mukhiya
            </Button>
          </div>

          {/* Conditional rendering if no Mukhiyas are found */}
          {mukhiyas.length === 0 ? (
            <p>अभी तक मुखिया नही बने है</p>
          ) : (
            <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%', textAlign: 'left' }}>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Mukhiya Name</th>
                  <th>Gautra</th>
                  <th>Subcatse</th>
                  <th>Village</th>
                </tr>
              </thead>
              <tbody>
                {mukhiyas?.map((mukhiya, index) => (
                  <tr key={mukhiya._id}>
                    <td>{index + 1}</td>
                    <td>{mukhiya?.mukhiya_name}</td>
                    <td>{mukhiya?.gautra}</td>
                    <td>{mukhiya?.subcaste}</td>
                    <td>{mukhiya?.village}</td>
                    {!mukhiya?.isMukhiya ? (
                      <td>
                        <Button
                          onClick={() => {
                            setSelectedMukhiya(mukhiya); // Set the Mukhiya to be edited
                            setOpenMukhiyaEditModal(true);
                          }}
                          style={{ color: 'blue', marginRight: '10px' }}
                        >
                          Edit
                        </Button>
                        <Button onClick={() => handleDeleteMukhiya(mukhiya)} style={{ color: 'red' }}>
                          Delete
                        </Button>
                      </td>
                    ) : (
                      <td>
                        <Button component="a" target="_blank" href={`/family-tree?idOfUser=${mukhiya.graph_node_id}`}>
                          Click for edit
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {MukhiyaSearchModal && (
            <SearchModal
              isMukhiyaForm={true}
              selectButton={false}
              fields={['mobileNo']}
              onClose={() => setMukhiyaSearchModal(false)}
              handleNoneOfTheseClick={handleAddMukhiya}
            />
          )}

          {openMukhiyaEditModal && (
            <Modal toggle={onClose}>
              <Card>
                <CardContent>
                  <SearchForm
                    updateMukhiya={true}
                    search_params={{
                      name: selectedMukhiya?.mukhiya_name,
                      father_name: selectedMukhiya?.mukhiya_father_name,
                      gautra: selectedMukhiya?.gautra,
                      village: selectedMukhiya?.village,
                      mobileNo: selectedMukhiya?.mobile,
                      subcaste: selectedMukhiya?.subcaste,
                    }}
                    sendResults={(searchParams) => handleUpdateMukhiya(searchParams)}
                    fields={['name', 'father', 'gautra', 'village', 'mobileNo']}
                    //  gender_value={selectedMukhiya.gender}
                    heading="Edit mukhiya details"
                    buttonText="Update"
                  />
                </CardContent>
              </Card>
            </Modal>
          )}
        </>
      )}
    </div>
  );
}

export default MukhiyasList;
